import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PageHome = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <section className="py-5 text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <h1 className="fw-light">Akumulatory B.B. Battery</h1>
            <p className="lead text-muted">
              {" "}
              Zasilanie awaryjne, UPS.
            </p>
          </div>
        </div>
        <div className="row">
          <Link to="/kontakt/" className="btn btn-primary my-2">Kontakt</Link>
        </div>
      </section>
    </Layout>
  );
}
 
export default PageHome;
